import React, { useState, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';

function NavBar(props) {

  const [button0Style, setButton0Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button1Style, setButton1Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button2Style, setButton2Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button3Style, setButton3Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [buttonStyleNormal] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  const [buttonStyleHover] = useState({
    backgroundColor: 'white',
    border: '0px',
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    color: '#C981F0',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  function mouseHover(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleHover);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleHover);
    }
    else if(buttonIndex === 2) {
      setButton2Style(buttonStyleHover);
    }
    else if(buttonIndex === 3) {
      setButton3Style(buttonStyleHover);
    }
  };

  function mouseOut(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleNormal);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleNormal);
    }
    else if(buttonIndex === 2) {
      setButton2Style(buttonStyleNormal);
    }
    else if(buttonIndex === 3) {
      setButton3Style(buttonStyleNormal);
    }
  };

  return (
    props.windowSize.innerWidth > 750 && (
      <div style={styles.navBar}>
        <Link to={'/'} >
          <button onMouseOver={() => mouseHover(0)} onMouseOut={() => mouseOut(0)} className={'main'} style={button0Style} onClick={() => console.log('click')}>
            Home
          </button>
        </Link>

        <Link to="/PrivacyPolicy" >
          <button onMouseOver={() => mouseHover(1)} onMouseOut={() => mouseOut(1)} className={'main'} style={button1Style} onClick={() => console.log('click')}>
            Privacy Policy
          </button>
        </Link>

        <Link to="/Contact" >
          <button onMouseOver={() => mouseHover(2)} onMouseOut={() => mouseOut(2)} className={'main'} style={button2Style} onClick={() => console.log('click')}>
            Contact
          </button>
        </Link>

        <Link to="/Support" >
          <button onMouseOver={() => mouseHover(3)} onMouseOut={() => mouseOut(3)} className={'main'} style={button3Style} onClick={() => console.log('click')}>
            Support
          </button>
        </Link>

        
        {/* <button onMouseOver={() => mouseHover(1)} onMouseOut={() => mouseOut(1)} className={'main'} style={button1Style} onClick={() => console.log()}>
          Privacy Policy
        </button> */}
      </div>
    )
    ||
    (
      <div style={styles.navBarMobile}>
        <Link to={'/'} >
          <button onMouseOver={() => mouseHover(0)} onMouseOut={() => mouseOut(0)} className={'main'} style={button0Style} onClick={() => console.log('click')}>
            Home
          </button>
        </Link>

        <Link to="/PrivacyPolicy" >
          <button onMouseOver={() => mouseHover(1)} onMouseOut={() => mouseOut(1)} className={'main'} style={button1Style} onClick={() => console.log('click')}>
            Privacy Policy
          </button>
        </Link>

        <Link to="/Contact" >
          <button onMouseOver={() => mouseHover(2)} onMouseOut={() => mouseOut(2)} className={'main'} style={button2Style} onClick={() => console.log('click')}>
            Contact
          </button>
        </Link>

        <Link to="/Support" >
          <button onMouseOver={() => mouseHover(3)} onMouseOut={() => mouseOut(3)} className={'main'} style={button3Style} onClick={() => console.log('click')}>
            Support
          </button>
        </Link>

        
        {/* <button onMouseOver={() => mouseHover(1)} onMouseOut={() => mouseOut(1)} className={'main'} style={button1Style} onClick={() => console.log()}>
          Privacy Policy
        </button> */}
      </div>
    )
  );

  

}

let styles = {
  navBar: {
    // position: 'fixed',
    // border: '3px solid purple',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#C981F0',
    width: '100%',
  },
  navBarMobile: {
    // position: 'fixed',
    // border: '3px solid purple',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#C981F0',
    // width: '100%',
    flexDirection: 'column',
    overflow: 'hidden'
  },
};

export default NavBar;