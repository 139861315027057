import React, { useState, useEffect } from 'react';
import logo from './assets/logo-icons/Icon-8.png';
// import backgroundImage from './assets/UI/Backgrounds/Welcome/Gluten-free@3x.jpg';
import './App.css';
import './index.css';
import { Route, Link } from 'react-router-dom';
import { IoMailOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";


function Contact() {

 

  useEffect(function() {
    
  });


  const [button0Style, setButton0Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button1Style, setButton1Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [buttonStyleNormal] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  const [buttonStyleHover] = useState({
    backgroundColor: 'white',
    border: '0px',
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    color: '#81CFF0',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  function mouseHover(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleHover);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleHover);
    }
  };

  function mouseOut(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleNormal);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleNormal);
    }
  };

  return (
    <div style={styles.container}>
      <title>Nüri | Contact</title>
      
      <div style={{marginTop: 100, paddingBottom: 0, height: 'auto', width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
      <p style={{fontWeight: 'bold', marginBottom: 0, width: '50%', color: '#4d4d4d', fontSize: 26, textAlign: 'center', marginTop: 0, }} className={'main'}>
        {`Got any questions or concerns?`}
      </p>
      <p style={{marginBottom: 20, width: '50%', color: '#4d4d4d', fontSize: 20, textAlign: 'center', marginTop: 0, }} className={'main'}>
        {`Feel free to shoot us an email or call our customer support line.`}
      </p>
      <IoCalendarOutline style={{alignSelf: 'center', marginBottom: 5}} size={50} />
      <h5 style={{width: '50%', color: '#4d4d4d', fontSize: 20, textAlign: 'center', marginTop: 0, marginBottom: 0}} className={'main'}>
        {`Customer support hours`}
      </h5>
      <p style={{width: '50%', color: '#4d4d4d', fontSize: 20, textAlign: 'center', marginTop: 0, marginBottom: 20}} className={'main'}>
        {`Monday to Friday, 8am to 6pm.`}
      </p>
      <IoMailOutline style={{alignSelf: 'center'}} size={50} />
      <h3 style={{ color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0 }} className={'main'}>
        
        {`Nüri Customer support e-mail`}
      </h3>
      <p style={{ color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 20 }} className={'main'}>
        <a href="mailto:support@nurieats.com" style={{ fontWeight: 'bold', color: '#C981F0', textDecoration: 'none' }}>
          {`support@nurieats.com`}
        </a>
      </p>
      <IoCallOutline style={{alignSelf: 'center'}} size={50} />
      <h3 style={{ color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0 }} className={'main'}>
        {`Nüri Customer support toll-free hotline`}
      </h3>
      <p style={{ color: '#4d4d4d', fontSize: 24, textAlign: 'center', marginTop: 0, marginBottom: 0 }} className={'main'}>
        <a href="tel:+18776380995" style={{ fontWeight: 'bold', color: '#C981F0', textDecoration: 'none' }}>
          {`1-(877)-638-0995`}
        </a>
      </p>
      </div>
    </div>
  );
}

let styles = {

  container: {
    width: '100%', 
    height: '100%',
    // backgroundImage: `url(${backgroundImage})`,
    // border: '6px solid red',
    // backgroundImage: `url(${backgroundImage})`,
  },

};

export default Contact;
