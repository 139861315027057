import React, { useState, useEffect } from 'react';
import logo from './assets/logo-icons/Icon-8.png';
// import backgroundImage from './assets/UI/Backgrounds/Welcome/Gluten-free@3x.jpg';
import './App.css';
import './index.css';
import { Route, Link } from 'react-router-dom';

function PrivacyPolicy() {

 

  useEffect(function() {
    
  });


  const [button0Style, setButton0Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [button1Style, setButton1Style] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    fontSize: 20,
  });

  const [buttonStyleNormal] = useState({
    backgroundColor: '#C981F0',
    border: '0px',
    color: 'white',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  const [buttonStyleHover] = useState({
    backgroundColor: 'white',
    border: '0px',
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    color: '#81CFF0',
    height: 33,
    width: 200,
    borderWidth: 1,
    fontSize: 20,
  });

  function mouseHover(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleHover);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleHover);
    }
  };

  function mouseOut(buttonIndex) {
    if(buttonIndex === 0) {
      setButton0Style(buttonStyleNormal);
    }
    else if(buttonIndex === 1) {
      setButton1Style(buttonStyleNormal);
    }
  };

  return (
    <div style={styles.container}>
      <title>Nüri | Privacy Policy</title>
      <div style={{paddingBottom: 0, height: 'auto', width: '100%', border: '0px solid red', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
        <p style={{fontWeight: 'bold', textAlign: 'center', width: '80%', margin: 0, marginTop: 30}}  class={'main'}>PRIVACY POLICY</p>
        <p style={{textAlign: 'left', width: '80%'}} className={'main'}>
          Nüri Inc. (hereinafter “Nüri”, “we”, “us” or “our”) is committed to protecting your personal information. This Privacy
          Policy describes how we treat the information we collect when you use the services or applications offered or
          operated from time to time by Nüri (the “Nüri Platform”).<br/><br/>
          Please take the time to read this Privacy Policy carefully. This Privacy Policy should be read in conjunction with
          the terms and conditions contained in the Nüri Terms of Use (the “Terms of Use”). For your reference, we have
          added links to the Terms of Use and this Privacy Policy on all applicable services or applications under the Nüri
          Platform.<br/><br/>
          By using the Nüri Platform or otherwise providing us with your personal information, you are accepting the
          practices described in this Privacy Policy, as they may be amended by us from time to time, and agreeing to our
          collection and use of your personal information in accordance with this Privacy Policy. If you do not agree to the
          collection, use and disclosure of your personal information in this way, please do not use the Nüri Platform or
          otherwise provide Nüri with your personal information.<br/><br/>
          1. PRIVACY STATEMENT<br/>
          Our policies and practices have been designed to comply with the Personal Information Protection and
          Electronic Documents Act (the “Act”) and any other relevant privacy legislation.<br/>
          Nüri respects the privacy of each individual with whom it comes into contact as a user of the Nüri Platform. Nüri
          will not sell or otherwise provide any of your personal information to any organization or individual unless you
          give us permission to do so.<br/><br/>
          2. PERSONAL INFORMATION<br/>
          Personal information is information that refers to an individual specifically and that is recorded in any form.
          Personal information includes such things as full legal name, date of birth, age, full address, city, state/province,
          country and postal code, but does not include aggregated data from which the identity of an individual cannot be
          determined. In some cases, Nüri may ask the user for additional personal information, such as a valid driver’s
          license – for the sole purposes of providing safety and security to other Nüri users. Information about individuals
          contained in the following documents is not considered personal information:<br/><br/>
          • public telephone directories, where the subscriber can refuse to be listed;<br/>
          • professional and business directories available to the public;<br/>
          • public registries and court records; and<br/>
          • other publicly available printed and electronic publications.<br/><br/>
          We are responsible only for the personal information under our control. Third party services (i) that assist us in
          providing the Nüri Platform; (ii) that you link to through your use of the Nüri Platform; (iii) that may collect
          information about you themselves; or (iv) that we may share your information with in accordance with this
          Privacy Policy are governed by the respective privacy policies of those third parties.<br/>
          Except as set out in this Privacy Policy, Nüri does not collect any other personal information that you do not
          expressly provide. If you do not want your personal information collected, you are not obligated to provide it to
          us. If you have already submitted the information and would like us to remove it from our records, or you would
          like to know what information is held in our records or wish to have any of the information updated or modified,
          please contact us at the address set forth below. Nüri will not disclose your personal information to any third
          party without your consent, except as detailed in this privacy policy.<br/><br/>
          However, if you do not provide us with certain information, your use of the Nüri Platform may be restricted or
          prevented altogether as we require such information for the purposes set out in this privacy policy and to
          generally provide you with the services under the Nüri Platform. The information we may collect and analyze
          includes:<br/><br/>
           your name;<br/>
           the Internet protocol (IP) address used to connect your computer to the Internet;<br/>
           your login information for the Nüri Platform;<br/>
           your e-mail address;<br/>
           your password for the Nüri Platform; and<br/>
           computer and connection information, such as browser type, version and time zone setting, browser<br/>
          plug-in types and versions, operating system, and platform.
          <br/><br/>
          We may also use browser data such as cookies (alphanumeric identifiers that are transferred to your computer’s
          hard drive through your web browser, to enable our systems to recognize your browser and to provide certain
          features), Flash cookies (also known as Flash Local Shared Objects), or similar data on certain parts of our
          website for fraud prevention and other purposes. During some visits we may use software tools such as
          JavaScript to measure and collect session information, including page response times, download errors, length
          of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods
          used to browse away from the page.<br/><br/>
          If you are accessing the Nüri Platform from a web browser, the “help” portion of the toolbar on most browsers will
          tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you
          receive a new cookie, or how to disable cookies altogether.<br/><br/>
          3. USE OF PERSONAL INFORMATION<br/>
          Nüri identifies the purpose for which your personal information is collected and will be used or disclosed. If that
          purpose is not listed below, we will do this before or at the time the information is actually being collected. You
          will be deemed to consent to our use of your personal information for the purpose of:<br/><br/>
           facilitating your use of the Nüri Platform;<br/>
           communicating with you generally about the Nüri Platform and the services provided through the
          Nüri Platform;<br/>
           registering and/or creating your user account with the Nüri Platform;<br/>
           administering and managing the Nüri Platform services and our business operations;<br/>
           protecting against fraud or error;<br/>
           understanding and responding to your needs and preferences, including, but not limited to,
          communicating with you and to provide you with support and other services;
           providing services requested by you including, without limitation, the Nüri Platform services;<br/>
           recommending products and services that we believe will be of interest and provide value to you;<br/>
           performing statistical analyses of user behaviour and characteristics, in order to measure interest in
          and use of the various sections of the Nüri Platform so as to improve design and navigation of the
          Nüri Platform and to gather marketing information;<br/>
           troubleshooting problems and technical or security issues;<br/>
           detecting and protecting against error, fraud or other criminal activity as required by any applicable
          laws, rules or regulations or governmental requirements;<br/>
           complying with legal and governmental requirements;<br/>
           processing payments for the Nüri Platform services; and<br/>
           fulfilling any other purpose that would be reasonably apparent to the average person at the time we
          collect it from you.<br/><br/>

          We may also maintain and use records of such information to send you updates and news about Nüri or other
          products or services that may be of interest to you. If you do not want to receive updates, newsletters or other
          notifications from us, you may opt out of receiving further communications from us by using the response
          mechanism in our communications with you.<br/>
          Any personal information or content that you voluntarily disclose while using the Nüri Platform, which can be
          viewed by other users thereof, becomes publicly viewable and can be collected and used by others.
          Due to the global nature of the Internet, you may be transferring your personal information to countries outside of
          your jurisdiction of residence, whose legal systems do not offer the same protection for your data as the laws of
          your jurisdiction of residence.<br/><br/>
          4. NON-PERSONAL INFORMATION<br/>
          It is possible that we may also, now or in the future, request non-personally identifiable information, like gender,
          city of residence and age. We may use this data to improve the Nüri Platform and/or personalize your
          experience using the Nüri Platform. This information will only be shared in an aggregate, anonymous form, which
          means that the information will not contain any personally identifiable information about you or any other person.<br/><br/>
          5. USAGE INFORMATION<br/>
          In addition to collecting personal and non-personal information, Nüri and our current and/or future third party
          service providers may also use a variety of technologies that collect non-personal, aggregated information about
          the registered users’ use of the Nüri Platform. Usage Information may include the browser you are using, the
          URL that referred you to the Nüri Platform, all of the areas within the Nüri Platform that you visit and the time of
          day, among other information. This information is not personally identifiable and will only be used to find out how
          our registered users use the Nüri Platform. The collection of this information allows us, among other things, to
          create new features, prepare for traffic load demands and to efficiently distribute any email communications.<br/><br/>
          6. EMAIL COMMUNICATIONS<br/>
          From time to time we may send you e-mail with information and/or special offers (including for third party
          products or services) that we believe would be of interest to you. All such communications shall be made in full
          compliance with Canada’s Anti-Spam Legislation. Nüri will not send e-mail communications to you without your
          express consent and in accordance with Canada’s Anti-Spam Legislation. Nüri may use a reputable third party
          to deliver email. Such third party is under a contractual requirement to keep all personal information confidential
          and to only use any personal information for the sole purpose of sending you email on behalf of Nüri and to
          return any personal information once their services are completed.<br/><br/>
          7. SHARING INFORMATION<br/>
          Except with your consent and as set out herein, we will not sell, license, trade or disclose your personal
          information to or with others.<br/>
          We may disclose or share non-personally identifiable information with partners, advertisers or prospective
          partners or advertisers to help explain our business and the effectiveness of our business or for promotional
          purposes. For example, we may disclose aggregated demographic information which does not include any
          personally identifiable information.<br/>
          Nüri may disclose personal information to third party providers of Nüri, including third parties engaged to assist
          us in providing the Nüri Platform (including, but not limited to, software development and hosting, data
          processing, transaction processing services, document management and office administration) to you or to carry
          out one or more of the uses described above. These service providers are prohibited from using your personal
          information for any purpose other than to provide this assistance and are required to protect personal
          information disclosed by Nüri and to comply with applicable privacy laws.<br/>
          We may use service providers located outside of Canada, and, if applicable, your personal information may be
          processed and stored in other countries and therefore may be subject to disclosure under the laws of those
          countries.<br/>
          The Nüri Platform may include third-party advertising and links to other websites. We do not provide any
          personally identifiable customer information to these advertisers or third-party websites.<br/>
          These third-party websites and advertisers, or Internet advertising companies working on their behalf,
          sometimes use technology to send (or “serve”) the advertisements that appear on the Nüri Platform directly to
          your browser or app. They automatically receive your IP address when this happens. They may also use
          cookies, JavaScript, web beacons (also known as action tags or single-pixel gifs), and other technologies to
          measure the effectiveness of their ads and to personalize advertising content. We do not have access to or
          control over cookies or other features that they may use, and the information practices of these advertisers and
          third-party websites are not covered by this Privacy Notice. Please contact them directly for more information
          about their privacy practices. In addition, the Network Advertising Initiative offers useful information about
          Internet advertising companies (also called “ad networks” or “network advertisers”), including information about
          how to opt-out of their information collection. You can access the Network Advertising Initiative at
          http://www.networkadvertising.org.<br/><br/>
          Nüri reserves the right to disclose personal information to a third party if a law, regulation, search warrant,
          subpoena or court order legally requires or authorizes us to do so.
          Nüri also reserves the right to disclose and/or transfer personal information to a third party in the event of a
          proposed or actual purchase, sale (including a liquidation, realization, foreclosure or repossession), lease,
          merger, amalgamation or any other type of acquisition, disposal, transfer, conveyance or financing of all or any
          portion of Nüri or of any of the business or assets or shares of Nüri or a division thereof. Nüri will secure an
          undertaking from any such third party to treat the disclosed and/or transferred personal information with the
          same degree of diligence as had been previously provided by Nüri.
          You are deemed to consent to disclosure of your personal information for the purposes set out above.<br/><br/>
          8. ACCURACY AND RETENTION OF PERSONAL INFORMATION<br/>
          Nüri endeavors to ensure that any personal information in its possession is as accurate, current and complete as
          necessary for the purposes for which we use that information. If we become aware that personal information is
          inaccurate, incomplete or out of date, we will revise the personal information.
          We keep your personal information only as long as it is required for the reasons it was collected. The length of
          time we retain information varies, depending on the purpose for which it was collected and the nature of the
          information. This period may extend beyond the end of your relationship with us, but it will be only for so long as
          it is necessary for us to have sufficient information to respond to any issues that may arise at a later date.<br/><br/>
          9. SECURITY<br/>
          We use physical, electronic, and administrative safeguards to assist us in preventing unauthorized access,
          maintaining data accuracy and correctly using your personal information. Except as specified in this Privacy
          Policy, we use commercially reasonable efforts to limit access to your personal information to the employees,
          agents, and officers of Nüri on a need-to-know basis.<br/><br/>
          10. CHANGES TO THIS PRIVACY POLICY<br/>
          Nüri reserves the right to make changes to this Privacy Policy from time to time by posting the updated version
          of this Privacy Policy on the Nüri Platform. You are bound by any changes to this Privacy Policy when you use
          the Nüri Platform after such changes have been posted. We are committed to protecting your information and
          will never materially change our policies and practices to make them less protective of customer information
          collected in the past without the consent of affected customers.<br/><br/>
          11. CONTACT<br/>
          If you wish to know what information we have in our files about you, ask a question about your personal
          information in our possession, request a change or correction to such information or if you have any questions
          regarding this Privacy Policy, please contact us here:<br/><br/>
          Nüri Inc.<br/>
          PO Box 30036 Ridley Square<br/>
          St. Catharines, ON<br/>
          L2S 4A1<br/>
          Email: admin@nurieats.com<br/>
        </p>
      </div>
    </div>
  );
}

let styles = {

  container: {
    width: '100%', 
    height: 'auto',
    
    // backgroundImage: `url(${backgroundImage})`,
    // border: '6px solid red',
    // backgroundImage: `url(${backgroundImage})`,
  },

};

export default PrivacyPolicy;
