import React, { useState, useEffect } from 'react';
import logo from './assets/logo-icons/Icon-8.png';
// import backgroundImage from './assets/UI/Backgrounds/Welcome/Gluten-free@3x.jpg';
import './App.css';
import './index.css';
import { Route, Routes, Link } from 'react-router-dom';
import NavBar from './components/NavBar.js';
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import Contact from "./Contact";
import Support from "./Support";
import AccountDeletion from "./AccountDeletion";

//TEST NURI INC

function App() {

  var [number, setNumber] = useState(window.outerWidth);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }


  return (

    windowSize.innerWidth > 750 && (
      <div style={styles.container}>

        <div style={styles.header}>
          {/* <img style={{width: 120, height: 120}} src={logo}/> */}
          <div style={styles.logo}>
            <h1 style={{letterSpacing: -1, float: 'left', lineHeight: 0, fontSize: 50, fontWeight: 'normal', color: 'white'}} className='main'>n</h1>
            <h1 style={{letterSpacing: -1,  float: 'left', lineHeight: 0, fontSize: 50, fontWeight: 'bold', color: '#C981F0'}} className='main'>ü</h1>
            <h1 style={{letterSpacing: -1,  float: 'left', lineHeight: 0, fontSize: 50, fontWeight: 'normal', color: 'white'}} className='main'>r</h1>
            <h1 style={{letterSpacing: -1, float: 'left', marginBottom: 0, fontSize: 50, lineHeight: 0, fontWeight: 'normal', color: 'white'}} className='main'>i</h1>
            {/* <h1 style={{marginTop: 30, marginLeft: 3, float: 'left',fontSize: 15, lineHeight: 0, fontWeight: 'normal', color: 'white'}} className='main'>™</h1> */}
          </div>
          <div style={styles.slogan}>
            <h2 style={{fontSize: 20, marginTop: 0, lineHeight: 0, color: 'white', fontWeight: 'normal'}} className={'main'}>Feed your passion.</h2>
          </div>
          
        </div>

        

        <NavBar windowSize={windowSize} />
        
        <Routes>
          <Route path="/" exact element={ <Home windowSize={windowSize} /> } />
          <Route path="/PrivacyPolicy" exact element={ <PrivacyPolicy windowSize={windowSize}/> } />
          <Route path="/TermsOfUse" exact element={ <TermsOfUse windowSize={windowSize}/> } />
          <Route path="/Contact" exact element={ <Contact windowSize={windowSize}/> } />
          <Route path="/Support" exact element={ <Support windowSize={windowSize}/> } />
          <Route path="/AccountDeletion" exact element={ <AccountDeletion windowSize={windowSize}/> } />
        </Routes>
        
        <footer style={styles.footer}>
          <div style={{width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{ height: '65%', width: '60%', display: 'flex', flexDirection: 'row', padding: 15}}>
              <div style={{ padding: 10,  borderRight: '1px solid white', width: '33.33%', height: '100%'}}>
                <p style={{marginTop: 2.5,  color: 'white', fontWeight: 'bold', lineHeight: 1}} className={'main'}>NURI INC.</p>
                <p style={{color: 'white', fontWeight: 'bold', fontSize: 14, lineHeight: 1,}} className={'main'}>Feed your passion.</p>
              </div>
              <div style={{  padding: 10,  borderRight: '1px solid white', width: '33.33%', height: 'auto'}}>
                <Link to={'/TermsOfUse'}>
                  <a href={'https://nurieats.com/TermsOfUse'} style={{lineHeight: 0, textDecoration: 'none', color: 'white', lineHeight: 0, }} className={'main'}>Terms of Use</a>
                </Link>
              </div>
              <div style={{  padding: 10,  borderRight: '1px solid white', width: '33.33%', height: 'auto'}}>
                <Link to={'/PrivacyPolicy'}>
                  <a href={'https://nurieats.com/PrivacyPolicy'} style={{lineHeight: 0, textDecoration: 'none', color: 'white', lineHeight: 0, }} className={'main'}>Privacy Policy</a>
                </Link>
              </div>
              <div style={{  padding: 10,  borderRight: '1px solid white', width: '33.33%', height: 'auto'}}>
                <Link to={'/Contact'}>
                  <a href={'https://nurieats.com/Contact'} style={{lineHeight: 0, textDecoration: 'none', color: 'white', lineHeight: 0, }} className={'main'}>Contact</a>
                </Link>
              </div>
              <div style={{  padding: 10,  border: '0px solid black', width: '33.33%', height: 'auto'}}>
                <Link to={'/Support'}>
                  <a href={'https://nurieats.com/Support'} style={{lineHeight: 0, textDecoration: 'none', color: 'white', lineHeight: 0, }} className={'main'}>Support</a>
                </Link>
              </div>
            </div>
          </div>
          
          <div className={'main'} style={{padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '0px solid black', height: '35%', color: 'white'}}>
            {`© ${currentYear} Copyright`} <a style={{paddingLeft: 5, color: 'white', textDecoration: 'none'}} href={'https://nurieats.com'}>nurieats.com</a>
          </div>
        </footer>

      </div>
    )
    ||
    (
      <div style={styles.container}>

        <div style={styles.header}>
          
          {/* <img style={{width: 120, height: 120}} src={logo}/> */}
          <div style={styles.logo}>
            <h1 style={{letterSpacing: -1, float: 'left', lineHeight: 0, fontSize: 50, fontWeight: 'normal', color: 'white'}} className='main'>n</h1>
            <h1 style={{letterSpacing: -1,  float: 'left', lineHeight: 0, fontSize: 50, fontWeight: 'bold', color: '#C981F0'}} className='main'>ü</h1>
            <h1 style={{letterSpacing: -1,  float: 'left', lineHeight: 0, fontSize: 50, fontWeight: 'normal', color: 'white'}} className='main'>r</h1>
            <h1 style={{letterSpacing: -1, float: 'left', marginBottom: 0, fontSize: 50, lineHeight: 0, fontWeight: 'normal', color: 'white'}} className='main'>i</h1>
            {/* <h1 style={{marginTop: 30, marginLeft: 3, float: 'left',fontSize: 15, lineHeight: 0, fontWeight: 'normal', color: 'white'}} className='main'>™</h1> */}
          </div>
          <div style={styles.slogan}>
            <h2 style={{fontSize: 20, marginTop: 0, lineHeight: 0, color: 'white', fontWeight: 'normal'}} className={'main'}>Feed your passion.</h2>
          </div>
          
        </div>

        

        <NavBar windowSize={windowSize} />
        
        <Routes>
          <Route path="/" exact element={ <Home windowSize={windowSize} /> } />
          <Route path="/PrivacyPolicy" exact element={ <PrivacyPolicy windowSize={windowSize}/> } />
          <Route path="/TermsOfUse" exact element={ <TermsOfUse windowSize={windowSize}/> } />
          <Route path="/Contact" exact element={ <Contact windowSize={windowSize}/> } />
          <Route path="/Support" exact element={ <Support windowSize={windowSize}/> } />
          <Route path="/AccountDeletion" exact element={ <AccountDeletion windowSize={windowSize}/> } />
        </Routes>
        
        <footer style={styles.footer}>
          <div style={{width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{ height: '65%', width: '60%', display: 'flex', flexDirection: 'row', padding: 15}}>
              <div style={{ padding: 10,  borderRight: '1px solid white', width: '33.33%', height: '100%'}}>
                <p style={{marginTop: 2.5,  color: 'white', fontWeight: 'bold', lineHeight: 1}} className={'main'}>NURI INC.</p>
                <p style={{color: 'white', fontWeight: 'bold', fontSize: 14, lineHeight: 1,}} className={'main'}>Feed your passion.</p>
              </div>
              <div style={{  padding: 10,  borderRight: '1px solid white', width: '33.33%', height: 'auto'}}>
                <Link to={'/TermsOfUse'}>
                  <a href={'https://nurieats.com/TermsOfUse'} style={{lineHeight: 0, textDecoration: 'none', color: 'white', lineHeight: 0, }} className={'main'}>Terms of Use</a>
                </Link>
              </div>
              <div style={{  padding: 10,  borderRight: '1px solid white', width: '33.33%', height: 'auto'}}>
                <Link to={'/PrivacyPolicy'}>
                  <a href={'https://nurieats.com/PrivacyPolicy'} style={{lineHeight: 0, textDecoration: 'none', color: 'white', lineHeight: 0, }} className={'main'}>Privacy Policy</a>
                </Link>
              </div>
              <div style={{  padding: 10,  borderRight: '1px solid white', width: '33.33%', height: 'auto'}}>
                <Link to={'/Contact'}>
                  <a href={'https://nurieats.com/Contact'} style={{lineHeight: 0, textDecoration: 'none', color: 'white', lineHeight: 0, }} className={'main'}>Contact</a>
                </Link>
              </div>
              <div style={{  padding: 10,  border: '0px solid black', width: '33.33%', height: 'auto'}}>
                <Link to={'/Support'}>
                  <a href={'https://nurieats.com/Support'} style={{lineHeight: 0, textDecoration: 'none', color: 'white', lineHeight: 0, }} className={'main'}>Support</a>
                </Link>
              </div>
            </div>
          </div>
          
          <div className={'main'} style={{padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '0px solid black', height: '35%', color: 'white'}}>
            {`© ${currentYear} Copyright`} <a style={{paddingLeft: 5, color: 'white', textDecoration: 'none'}} href={'https://nurieats.com'}>nurieats.com</a>
          </div>
        </footer>

      </div>
    )
    
  );
}

let styles = {
  header: {
    width: '100%',
    height: 'auto',
    paddingBottom: 15,
    paddingTop: 15,
    // border: '3px solid blue',
    backgroundColor: '#81CFF0',
    // paddingLeft: 35,
    // display: 'flex',
    // alignSelf: 'center',
    // position: 'fixed',

  },
  footer: {
    // position: 'fixed',
    // left:0,
    bottom: 0,
    // right:0,
    // border: '3px solid red',
    backgroundColor: '#81CFF0',
    // display: 'flex',
    width: '100%',
    // height: 'auto',
    marginTop: 'auto'

    // width: '100%',
  },
  navBar: {
    // border: '3px solid purple',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#C981F0',
  },

  button: {
    
    backgroundColor: '#C981F0',
    border: '1px solid white',
    // borderTopWidth: 0,
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    color: 'white',
    height: 35,
    width: 200,
    borderWidth: 1,
    fontSize: 10,
  },
  container: {
    width: '100%', height: 935,
    // backgroundImage: `url(${backgroundImage})`,
    // border: '6px solid red',
    // backgroundImage: `url(${backgroundImage})`,
  },
  
  body: {
    width: '100%',
    height: 'auto',
    // border: '3px solid lightGreen',
    
  },
  logo: {
    paddingLeft: 35,
    // display: 'block',
    // display: 'flex',
    // flexDirection: 'row',
    // border: '3px solid black',
    height: 80,
    // border: '1px solid black',
    marginBottom: -13,
  },
  slogan: {
    paddingLeft: 35,
    // border: '1px solid red',
    height: 30,
  },
};

export default App;
